import { config_utils } from '@/services/configs/config_utils'
import { list_functionality } from '@/services/list-functionality/list-functionality'
import request from '@/services/axios_instance'
import HtmlPreviewer from '@/modules/Builders/components/HtmlPreviewer.vue'
import Editor from '@/common/Editor/Editor.vue'
import DeleteDialog from '@/common/DeleteDialog.vue'
import InvoiceTemplateDialog from './components/InvoiceTemplateDialog.vue'
export default {
    name: 'Templates',
    mixins: [config_utils, list_functionality],
    components: {
        Editor,
        DeleteDialog,
        HtmlPreviewer,
        InvoiceTemplateDialog
    },
    data: () => ({
        invoice_template_dialog: false,
        paths: [
            { text: 'Admin', disabled: false, router_name: 'admin-dashboard' },
            { text: 'Templates', disabled: false, router_name: 'admin-templates' }
        ],
        activeType: null,
        activeInvoice: null,
        templates: null,
        submitting: false,
        content: '',
        core_templates: [],
        email_event: null,
        email_event_btn: false,
        invoice_templates: [],
        confirm_invoice_delete: false
    }),
    computed: {
        tabs() {
            return this.core_templates
        },
        events() {
            if (!this.email_event) return []
            return Object.keys(this.email_event)
        }
    },
    created() {
        this.getGlobalTemplates()
        this.getCoreTemplates()
        this.getEmailEvents()
        this.getInvoiceTemplates()
    },
    mounted() {
        this.$event.$emit('path-change', this.paths)
            //this.all()
    },
    methods: {
        append(slot) {
            this.content = `${this.content} ${slot}`
        },
        setActiveType(item) {
            this.activeType = item
            let index = this.templates.findIndex((i) => i.name.includes(item.type))
            this.content = ~index ? this.templates[index].template : null
        },
        getGlobalTemplates() {
            request.get(`api/template/email-templates/global`).then(({ data }) => {
                this.templates = data
            })
        },
        getInvoiceTemplates() {
            this.loading = true
            request
                .get(`api/template/invoice-templates?only_global=1`)
                .then(({ data }) => {
                    this.invoice_templates = data
                })
                .finally(() => (this.loading = false))
        },
        saveChanges() {
            if (this.content.trim() === '' || this.content.trim() === '<p></p>') {
                this.appSnackbar('Template cannot be empty', 'error')
                return
            }
            this.submitting = true
            let payload = {
                name: this.activeType.type,
                value: this.content
            }
            request
                .post(`api/template/email-templates/global`, payload)
                .then(({ data }) => {
                    let index = this.templates.findIndex((i) => i.id === data.id)
                    if (~index) {
                        this.templates.splice(index, 1, data)
                    }
                    this.appSnackbar('Template saved.')
                })
                .finally(() => {
                    this.submitting = false
                })
        },
        getCoreTemplates() {
            this.loading = true
            request
                .get(`api/emails/templates`)
                .then(({ data }) => {
                    this.core_templates = data
                    if (data.length) this.setActiveType(data[0])
                })
                .finally(() => (this.loading = false))
        },
        getEmailEvents() {
            request.get(`api/configs/email_events`).then(({ data }) => {
                this.email_event = data
            })
        },
        save_allowed_email_event() {
            this.email_event_btn = true
            this.sendUpdateConfigs(
                `api/configs/allowed_email_events`, { list: this.email_event },
                (data) => {
                    this.email_event = data
                    this.email_event_btn = false
                }
            )
        },
        handleDeleteInvoiceTemplate() {
            this.loading = true
            request
                .delete(`api/template/invoices/${this.activeInvoice.id}`)
                .then(({ data }) => {
                    let index = this.invoice_templates.findIndex(
                        (i) => parseInt(i.id) === parseInt(data.id)
                    )
                    if (~index) {
                        this.invoice_templates.splice(index, 1)
                    }
                    this.activeInvoice = null
                    this.confirm_invoice_delete = false
                    this.appSnackbar('Invoice template deleted')
                })
                .finally(() => (this.loading = false))
        },
        actionInvoice(action, template) {
            this.activeInvoice = template
            if (action === 'delete') {
                this.confirm_invoice_delete = true
            }
            if (action === 'edit') {
                this.invoice_template_dialog = true
            }
            if (action === 'add') {
                this.invoice_template_dialog = true
            }
        },
        handleNewInvoiceTemplate(payload) {
            request.post(`api/template/invoices`, payload).then(({ data }) => {
                this.invoice_templates.push(data)
                this.invoice_template_dialog = false
                this.appSnackbar('New invoice template created')
            })
        },
        handleUpdateInvoiceTemplate(payload) {
            request
                .put(`api/template/invoices/${this.activeInvoice.id}`, payload)
                .then(({ data }) => {
                    let index = this.invoice_templates.findIndex(
                        (i) => i.id === this.activeInvoice.id
                    )
                    if (~index) {
                        this.invoice_templates.splice(index, 1, data)
                    }
                    this.invoice_template_dialog = false
                    this.activeInvoice = data
                    this.appSnackbar('Invoice template updated')
                })
        }
    }
}