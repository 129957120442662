<template>
  <div>
    <CustomDialog
      v-model="dialog"
      @button1="close"
      :title="title"
      :button2-text="saveButtonLabel"
      @button2="save"
      :main-btn-disabled="!valid || !hasPreview"
      :max-width="800"
    >
      <template v-slot:content>
        <v-sheet>
          <v-form v-model="valid">
            <v-row dense align="stretch" justify="center">
              <v-col cols="12" md="5" sm="12">
                <v-file-input
                  @change="handleUpload"
                  accept="image/*"
                  ref="fileUpload"
                  class="d-none"
                ></v-file-input>
                <div
                  @click.stop="$refs.fileUpload.$refs.input.click()"
                  class="
                    fill-height
                    d-flex
                    pa-2
                    align-center
                    justify-center
                    fullwidth
                    cursor-pointer
                    border-1 border-dotted
                    border-color--primary
                  "
                >
                  <v-img
                    v-if="mapping.attachment"
                    contain
                    :src="mapping.attachment.url_inline"
                  ></v-img>
                  <v-img
                    v-else
                    contain
                    :src="require('@/assets/temp/image-placeholder.png')"
                  ></v-img>
                </div>
              </v-col>
              <v-col cols="12" md="7" sm="12">
                <div class="fullwidth mb-2 d-flex flex-column">
                  <label class="primary--text subtitle-2">Name</label>
                  <v-text-field
                    hide-details="auto"
                    :rules="requiredRules"
                    outlined
                    dense
                    v-model="mapping.name"
                  >
                  </v-text-field>
                </div>
                <div class="fullwidth mb-2 d-flex flex-column">
                  <label class="primary--text subtitle-2">Component</label>
                  <v-text-field
                    hide-details="auto"
                    :rules="requiredRules"
                    outlined
                    dense
                    v-model="mapping.component"
                  >
                  </v-text-field>
                </div>
                <div class="fullwidth mb-2 d-flex flex-column">
                  <label class="primary--text subtitle-2">Template</label>
                  <v-textarea
                    auto-grow
                    rows="2"
                    hide-details="auto"
                    outlined
                    dense
                    v-model="mapping.template"
                    :rules="requiredRules"
                  >
                  </v-textarea>
                </div>
                <div class="fullwidth mb-2 d-flex flex-column">
                  <label class="primary--text subtitle-2">Colors (JSON)</label>
                  <v-textarea
                    rows="5"
                    hide-details="auto"
                    outlined
                    dense
                    v-model="mapping.colors"
                    :rules="requiredRules"
                  >
                  </v-textarea>
                </div>
              </v-col>
            </v-row>
          </v-form>
        </v-sheet>
      </template>
    </CustomDialog>
  </div>
</template>
<script>
import request from "@/services/axios_instance";
import CustomDialog from "@/common/BaseComponents/CustomDialog/CustomDialog.vue";
export default {
  name: "InvoiceTemplateDialog",
  components: {
    CustomDialog,
  },
  props: {
    value: { type: Boolean, default: false },
    item: { type: Object },
  },
  computed: {
    isEdit() {
      return this.item ? true : false;
    },
    saveButtonLabel() {
      return this.isEdit ? "Update" : "Save";
    },
    title() {
      return this.isEdit ? "" : "";
    },
    hasPreview() {
      return this.mapping.attachment ? true : false;
    },
  },
  data: () => ({
    dialog: false,
    iconDialog: false,
    valid: false,
    mapping: {
      name: null,
      colors: null,
      attachment: null,
      component: null,
      template: null,
      is_private: false,
    },
  }),
  watch: {
    dialog(val) {
      this.$emit("input", val);
    },
    value(val) {
      this.dialog = val;
    },
    item: {
      handler: function (val) {
        this.mapItem(val);
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    close() {
      this.dialog = false;
    },
    handleUpload(file) {
      if (
        file &&
        ["image/png", "image/jpeg", "image/jpg", "image/gif"].includes(
          file.type
        )
      ) {
        if (file && file.size <= this.settings.fileSizeMaxUploadLimit) {
          var formData = new FormData();
          formData.append("file", file);
          request
            .post(`api2/attachments/dropzone`, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then(({ data }) => {
              this.mapping.attachment = data;
            });
        } else {
           this.appSnackbar(
            `File size exceed ${this.settings.fileSizeMaxUploadInMB}MB`,
            "error"
          );
        }
      } else {
         this.appSnackbar(
          `File should be an image type (JPEG,PNG or GIF)`,
          "error"
        );
      }
    },
    mapItem(item) {
      this.mapping = {
        name: item ? item.name : null,
        component: item ? item.component : null,
        colors: item ? JSON.stringify(item.colors) : null,
        attachment: item ? item.preview_object : null,
        template: item ? item.template : null,
        is_private: item && item.company_id ? true : false,
      };
    },
    save() {
      this.$emit(this.isEdit ? "update" : "save", this.mapping);
    },
  },
};
</script>
 <style>
</style>